exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-insights-jsx": () => import("./../../../src/pages/insights.jsx" /* webpackChunkName: "component---src-pages-insights-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/about.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-clients-jsx": () => import("./../../../src/templates/clients.jsx" /* webpackChunkName: "component---src-templates-clients-jsx" */),
  "component---src-templates-contact-us-jsx": () => import("./../../../src/templates/contact-us.jsx" /* webpackChunkName: "component---src-templates-contact-us-jsx" */),
  "component---src-templates-default-jsx": () => import("./../../../src/templates/default.jsx" /* webpackChunkName: "component---src-templates-default-jsx" */),
  "component---src-templates-insights-jsx": () => import("./../../../src/templates/insights.jsx" /* webpackChunkName: "component---src-templates-insights-jsx" */),
  "component---src-templates-what-we-do-jsx": () => import("./../../../src/templates/what-we-do.jsx" /* webpackChunkName: "component---src-templates-what-we-do-jsx" */),
  "component---src-templates-wholevision-jsx": () => import("./../../../src/templates/wholevision.jsx" /* webpackChunkName: "component---src-templates-wholevision-jsx" */)
}

