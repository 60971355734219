import "bootstrap/dist/css/bootstrap.min.css"
import "./src/styles/theme.scss"

import * as React from "react"
import { ApolloProvider } from "@apollo/client"

import { client } from "./lib/apolloClient"

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
)
